/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import { storeRecent } from '../../../templates/helpers';
import { useCurrentTemplate } from '../../../templates/hooks';
import { checkIfTools } from '../../../tools/actions';
import req from '../../../../redux-setup';
import { getGeneratorId } from '../actions';
import { TAB_PROMPT, TAB_RESULTS, EQUALIZER_VALUE } from '../constants';
import { useStoredDynamicData } from '../hooks';
import { setOpenUpgradeModal } from '../../../../redux-setup/actions/upgradeModal';

function useTemplateData(templateCode, title, template, slug) {
  const location = useLocation();
  const dispatch = useDispatch();
  const templateData = useCurrentTemplate(slug);
  const isTools = checkIfTools(template);
  const {
    remaining_credits,
    isPaid,
    is_unlimited: isUnlimited,
    pack_credits,
  } = useSelector((state) => state['PERSONALIZE/DATA']);
  const buttonStatus = useSelector((state) => state.BUTTON_STATUS);
  const { id: profileId } = useSelector((state) => state.PROJECT);
  const templateValues = useSelector((state) => state.TEMPLATE_VALUES) || {};

  const formRef = React.useRef();

  useStoredDynamicData();

  const [equalizerValue, setEqValue] = React.useState({
    tone: 'Friendly',
    length: 'Medium',
  });
  const [viewCustomizeForm, setCustomizeForm] = React.useState(true);
  const [selectedTab, setSelectedTab] = useState(TAB_PROMPT);
  const contentValue = React.useMemo(
    () =>
      templateValues[isEmpty(templateData) ? template : templateData.code] ||
      {},
    [templateData, templateValues, template],
  );

  const showSkeleton = React.useMemo(
    () => buttonStatus === 'loading',
    [buttonStatus],
  );

  const addRecent = () => {
    storeRecent(
      title,
      `${location?.pathname}`,
      isTools ? 'recent-tools' : 'recent-templates',
    );
  };

  const handleFormSubmit = (values) => {
    const payload = { ...values };
    if (viewCustomizeForm) {
      payload.length = equalizerValue?.length;
      payload.tone = equalizerValue?.tone;
    }

    getGeneratorId(
      isEmpty(templateData) ? template : templateData.code,
      isEmpty(templateData)
        ? templateCode || title
        : {
            template_id: templateData?.templateId,
            template: templateData?.template,
          },
      profileId,
      payload,
      () => {},
      addRecent,
    );
  };

  const handleCreate = async () => {
    if (!isUnlimited && (remaining_credits + pack_credits) < 1) {
      dispatch(setOpenUpgradeModal(true));
      return;
    }

    // Validate before generating results
    if (viewCustomizeForm || isTools) {
      const errors = await formRef.current.validateForm();
      if (!isEmpty(errors)) {
        // we need to submit the form because formik is not showing the errors in the refered form
        formRef.current.handleSubmit();
        setSelectedTab(TAB_PROMPT);
        return;
      }
    }

    setSelectedTab(TAB_RESULTS);
    if (viewCustomizeForm || isTools) {
      formRef.current.handleSubmit();
      return;
    }
    // if they turn off the fine tune
    getGeneratorId(
      isEmpty(templateData) ? template : templateData.code,
      isEmpty(templateData)
        ? templateCode || title
        : {
            template_id: templateData?.templateId,
            template: templateData?.template,
          },
      profileId,
      null,
      () => {},
      addRecent,
    );
  };

  useEffect(
    () => () => {
      if (isTools) {
        req.omit(EQUALIZER_VALUE);
      }
    },
    [isTools],
  );

  const pageData = {
    formRef,
    template,
    templateCode: templateCode || title,
    templateTitle: title,
    viewCustomizeForm,
    setCustomizeForm,
    equalizerValue,
    setEqValue,
    isTools,
    selectedTab,
    setSelectedTab,
    handleFormSubmit,
    contentValue,
    showSkeleton,
    handleCreate,
    templateData,
    buttonStatus,
    isPaid,
  };

  return pageData;
}
export default useTemplateData;
