/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { clsx } from 'clsx';
import { useSelector } from 'react-redux';
import { getGeneratorId } from '../../partials/pagelayout/actions';
import { SELECTED_PERSONA } from '../../partials/pagelayout/constants';
import SkeletonGoogleAds from '../../templates/components/skeletonss/SkeletonGoogleAds';
import req, { useApiData } from '../../../redux-setup';

import { WRITER_DETAILS } from '../contants';
import { transformIntro } from '../transform';
import CreditsBar from '../../templates/components/CreditsBar';
import Button from '../../../common/Button';

function WriterIntro() {
  const {
    template: summary,
    topic,
    audience,
    tone,
  } = useApiData(WRITER_DETAILS, {});
  const { id } = useApiData(SELECTED_PERSONA, {});
  const { is_unlimited: isUnlimited } = useSelector(
    (state) => state['PERSONALIZE/DATA'],
  );
  const [isLoading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState('');
  const [intros, setIntros] = React.useState([]);
  const [newIntroFlag, setNewIntroFlag] = useState(false);

  const setIntrosArray = React.useCallback((res) => {
    setIntros(transformIntro(res));
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getGeneratorId(
      'V2 Template',
      'Writer Intro',
      id,
      {
        type: summary,
        topic,
        audience,
        tone,
      },
      null,
      setIntrosArray,
      188,
    );
  }, [id, summary, topic, setIntrosArray, newIntroFlag]);
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center flex-wrap">
        {!isUnlimited && (
          <div className="w-full mlg:hidden my-5">
            <div className="mx-auto w-64 lg:w-96 h-6">
              <CreditsBar />
            </div>
          </div>
        )}
        <h1 className="flex text-3xl xlg:text-3xl font-barlow font-medium mb-3 mt-5">
          Choose an intro
        </h1>
        <div className="flex items-center">
          {!isUnlimited && (
            <div className="hidden mlg:inline-block mx-auto w-64 lg:w-96 h-6 mr-4">
              <CreditsBar />
            </div>
          )}
          <Button
            type="secondary"
            disabled={isLoading}
            onClick={() => setNewIntroFlag((prev) => !prev)}
            className="mr-4"
          >
            Rewrite Intro
          </Button>
          <Button
            type="primary"
            onClick={() =>
              req.updateObject(WRITER_DETAILS, {
                step: 2,
                intro: selected,
              })
            }
            loading={isLoading}
            disabled={!selected || isLoading}
          >
            Continue to Writer
          </Button>
        </div>
      </div>
      <p className="text-sm italic mt-2">
        Don&apos;t worry, you can change this later
      </p>
      <div className="flex flex-col space-y-8 mt-8">
        {isLoading ? (
          <>
            <h1 className="mb-2 text-xl">
              Just a second, the gears are turning..
            </h1>
            <SkeletonGoogleAds />
          </>
        ) : (
          React.Children.toArray(
            intros
              ?.filter((tmp) => tmp)
              ?.map((template) => (
                <div
                  type="button"
                  className={clsx(
                    'text-left px-6 py-12 leading-6 rounded-xl shadow-md  border  bg-white text-main  font-semibold',
                    selected === template
                      ? 'border-ubblue'
                      : ' border-transparent text-main',
                  )}
                  onClick={() => setSelected(template)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: template,
                  }}
                />
              )),
          )
        )}
        {!isLoading && intros.length && (
          <div className="w-full flex justify-end">
            <Button
              type="secondary"
              loading={isLoading}
              disabled={isLoading}
              onClick={() => setNewIntroFlag((prev) => !prev)}
              className="mr-4"
            >
              Rewrite Intro
            </Button>
            <Button
              type="primary"
              onClick={() =>
                req.updateObject(WRITER_DETAILS, {
                  step: 2,
                  intro: selected,
                })
              }
              disabled={!selected}
            >
              Continue to Writer
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

WriterIntro.propTypes = {};

export default WriterIntro;
