/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable no-async-promise-executor */
/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getGeneratorId } from '../../partials/pagelayout/actions';
import {
  SELECTED_PERSONA,
} from '../../partials/pagelayout/constants';
import { PageLayoutContext } from '../../partials/pagelayout/hooks';
import req, { useApiData } from '../../../redux-setup';

import WriterIntro from '../components/WriterIntro';
import WriterTemplates from '../components/WriterTemplates';
import { WRITER_DETAILS, WRITER_SIDEBAR } from '../contants';
import { getCurrentDoc, hasStoredDoc } from '../helpers';
import { transformIntro } from '../transform';
// eslint-disable-next-line import/no-cycle
import WriterEditor from './WriterEditor';
import WriterModal from '../WriterModal';
import defaultWriterValue from './writerConstants';
import WriterOldModal from '../WriterOldModal';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');

function WriterContainer() {
  dayjs.extend(utc);
  const history = useHistory();
  const hasDoc = hasStoredDoc();
  const doc = getCurrentDoc();
  const [viewCustomizeForm, setCustomizeForm] = React.useState(false);
  const [equalizerValue, setEqValue] = React.useState({
    tone: 'Friendly',
    length: 'Medium',
  });
  const { type } = useApiData(WRITER_SIDEBAR, {});
  const [trialFinish, setTrialFinish] = useState(false);
  const [trialWorking, setTrialWorking] = useState(false);
  const [openModalTrial, setOpenModalTrial] = useState(false);
  const [openOldModal, setOpenOldModal] = useState(false);
  const formRef = React.useRef();
  const { step } = useApiData(WRITER_DETAILS, defaultWriterValue);
  const { isPaid, writer_trial_access, writer_trial_start } = useSelector(
    (state) => state['PERSONALIZE/DATA'],
  );
  const { id } = useApiData(SELECTED_PERSONA, {});
  const writerLeft = useMemo(() => {
    const finish = dayjs(writer_trial_start).add(3, 'day').utc(true);
    const now = dayjs();
    const daysLeft = finish.diff(now, 'day') + 1;
    switch (daysLeft) {
      case 3:
        return (
          <h2 className="flex items-center justify-center flex-wrap text-center">
            <svg
              className="inline-block mr-3"
              fill="#FFA300"
              width={23}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M250.4 235.3C269.1 216.6 299.5 216.6 318.2 235.3L324.7 241.8C343.4 260.5 343.4 290.9 324.7 309.6L201.6 432.7C197.4 436.9 192 439.9 186.2 441.2L136 452.7C118.8 456.7 103.3 441.2 107.3 423.1L118.8 373.8C120.1 367.1 123.1 362.6 127.3 358.4L250.4 235.3zM295.6 257.9C289.4 251.7 279.2 251.7 272.1 257.9L260.8 270.2L289.8 299.2L302.1 287C308.3 280.8 308.3 270.6 302.1 264.4L295.6 257.9zM267.2 321.8L238.2 292.8L149.1 380.1L141.3 418.7L179 410L267.2 321.8zM112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM416 192H32V448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96z" />
            </svg>
            <span className="font-barlow text-xl">
              Welcome! Enjoy your{' '}
              <span className="font-barlow font-medium">3-day</span> free trial!
            </span>
          </h2>
        );
      case 2:
        return (
          <h2 className="flex items-center justify-center flex-wrap text-center">
            <svg
              className="inline-block mr-3"
              fill="#FFA300"
              width={23}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M240 112C240 103.2 247.2 96 256 96C264.8 96 272 103.2 272 112V247.4L360.9 306.7C368.2 311.6 370.2 321.5 365.3 328.9C360.4 336.2 350.5 338.2 343.1 333.3L247.1 269.3C242.7 266.3 239.1 261.3 239.1 256L240 112zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32C132.3 32 32 132.3 32 256z" />
            </svg>
            <span className="font-barlow text-xl">
              You have <span className="font-barlow font-medium">2 days</span>{' '}
              left on your Writer trial.
            </span>
          </h2>
        );
      case 1:
        return (
          <h2 className="flex items-center justify-center flex-wrap text-center">
            <svg
              className="inline-block mr-3"
              fill="#FFA300"
              width={23}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M240 112C240 103.2 247.2 96 256 96C264.8 96 272 103.2 272 112V247.4L360.9 306.7C368.2 311.6 370.2 321.5 365.3 328.9C360.4 336.2 350.5 338.2 343.1 333.3L247.1 269.3C242.7 266.3 239.1 261.3 239.1 256L240 112zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32C132.3 32 32 132.3 32 256z" />
            </svg>
            <span className="font-barlow text-xl">
              You have
              <span className="font-barlow font-medium">&nbsp;1 day&nbsp;</span>
              left on your Writer trial.
            </span>
            &nbsp;
            <span className="font-barlow text-xl">
              To continue using this feature, please{' '}
              <button
                type="button"
                className="text-ubblue font-medium"
                onClick={() => history.push('/plan')}
              >
                &nbsp;upgrade&nbsp;
              </button>
              to a higher plan.
            </span>
          </h2>
        );
      default:
        return (
          <h2 className="font-barlow text-xl flex items-center justify-center">
            <svg
              className="inline-block mr-3"
              fill="#FFA300"
              width={23}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M240 112C240 103.2 247.2 96 256 96C264.8 96 272 103.2 272 112V247.4L360.9 306.7C368.2 311.6 370.2 321.5 365.3 328.9C360.4 336.2 350.5 338.2 343.1 333.3L247.1 269.3C242.7 266.3 239.1 261.3 239.1 256L240 112zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32C132.3 32 32 132.3 32 256z" />
            </svg>
            You have 1 day left on your Writer trial.
          </h2>
        );
    }
  }, [writer_trial_start]);
  const writeContent = async (
    lastParagraphs = '',
    template = 'V2 Template',
    template_id = 165,
    skipTransformIntro = false,
  ) =>
    new Promise(async (res) => {
      const payload = {
        original: lastParagraphs,
      };
      try {
        const data = await new Promise((resolve) => {
          getGeneratorId(
            null,
            {
              template,
              template_id,
            },
            id,
            payload,
            null,
            (dt) => resolve(dt),
          );
        });
        if (skipTransformIntro) {
          res(data?.contents);
          return;
        }
        res(
          transformIntro(data)?.length > 0 ? transformIntro(data).join('') : '',
        );
      } catch (_e) {
        res([]);
      }
    });

  const writerStep = [<WriterTemplates />, <WriterIntro />, <WriterEditor />];
  React.useEffect(() => {
    if (isPaid)
      req.set(WRITER_DETAILS, hasDoc ? doc?.writerState : defaultWriterValue);

    if (!isPaid) {
      if (writer_trial_access) {
        if (writer_trial_start) {
          const now = dayjs();
          const finish = dayjs(writer_trial_start).add(3, 'day').utc(true);
          if (finish.isAfter(now)) {
            setTrialWorking(true);
            setOpenModalTrial(false);
            req.set(
              WRITER_DETAILS,
              hasDoc ? doc?.writerState : defaultWriterValue,
            );
          } else {
            setTrialFinish(true);
            setOpenModalTrial(true);
            setTrialWorking(false);
          }
        } else {
          setOpenModalTrial(true);
        }
      } else {
        setOpenOldModal(true);
      }
    }

    return () => {
      req.omit(WRITER_DETAILS);
      req.omit(WRITER_SIDEBAR);
    };
  }, [writer_trial_start]);
  return isPaid || trialWorking ? (
    <PageLayoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        formRef,
        writeContent,
        template: 'writer',
        templateCode: 'Expander',
        templateTitle: 'Expander',
        viewCustomizeForm,
        setCustomizeForm,
        equalizerValue,
        setEqValue,
        isWriter: true,
      }}
    >
      <div className="container mx-auto max-w-screen-2xl h-full px-10">
        {trialWorking && <div className="bg-varden py-4">{writerLeft}</div>}
        {writerStep[step] || writerStep[0] || <div>Invalid writer</div>}
      </div>
    </PageLayoutContext.Provider>
  ) : (
    <>
      <WriterOldModal open={openOldModal} setOpen={setOpenOldModal} />
      <WriterModal
        open={openModalTrial}
        setOpen={setOpenModalTrial}
        trialFinish={trialFinish}
        setTrialWorking={setTrialWorking}
      />
    </>
  );
}

export default WriterContainer;
