import React from 'react';
import PropTypes from 'prop-types';
import { InlineLoader } from '@unbounce/ui-components/esm';
import TemplateContent from '../../partials/pagelayout/components/TemplateContent';
import { TAB_RESULTS } from '../../partials/pagelayout/constants';
import useTemplateData from '../../partials/pagelayout/containers/useTemplateData';
import { PageLayoutContext } from '../../partials/pagelayout/hooks';
import getSkeletons from '../../templates/components/skeletonss';
import TemplateFilter from './TemplateFilter';
import { useTemplateSidebar } from '../context';

function WriterTemplateLayout({ recommended }) {
  const { viewProfile, editor } = useTemplateSidebar();
  const data = useTemplateData(
    viewProfile?.template,
    viewProfile?.title,
    viewProfile?.type, /// code
    viewProfile?.slug,
  );
  const {
    showSkeleton,
    contentValue,
    handleCreate,
    selectedTab,
    buttonStatus,
  } = data;
  const Skeleton = getSkeletons[viewProfile?.template] || getSkeletons.default;
  const content = React.useMemo(
    () => (
      <>
        {showSkeleton && <Skeleton />}
        {!showSkeleton && Array.isArray(contentValue?.contents) ? (
          <div className="py-2 flex flex-col w-full space-y-4">
            {contentValue.contents
              .map((objC) =>
                typeof objC.content === 'string' ? objC.content.trim() : null,
              )
              .filter((d) => d)
              .map((dt) => (
                <div
                  key={dt.id}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {
                    const hlText = editor.selection.getContent();
                    if (!hlText) {
                      editor.execCommand('mceInsertContent', false, dt);
                      return;
                    }
                    editor.selection.setContent(dt);
                  }}
                  className="bg-white break-words p-5 shadow-md rounded-lg m-1 mt-2 border border-gray-200 hover:border-ubblue cursor-pointer text-sm font-source"
                  onClick={() => {
                    const hlText = editor.selection.getContent();
                    if (!hlText) {
                      editor.execCommand(
                        'mceInsertContent',
                        false,
                        dt.replaceAll('\n', '<p />'),
                      );
                      return;
                    }
                    editor.selection.setContent(dt.replaceAll('\n', '<p />'));
                  }}
                >
                  {dt.split('\n').map((line) => (
                    <>
                      <span key={line}>{line}</span>
                      <br />
                    </>
                  ))}
                </div>
              ))}
          </div>
        ) : (
          []
        )}
      </>
    ),
    [showSkeleton, contentValue.contents, editor],
  );
  return (
    <PageLayoutContext.Provider value={data}>
      <div className="flex justify-center items-center relative flex-col">
        <TemplateFilter recommended={recommended} />
        {buttonStatus !== 'loading' && (
          <button
            onClick={handleCreate}
            type="button"
            className="flex w-full items-center justify-center bg-ubblue hover:bg-ubblue-hover text-white text-sm py-2 px-4 font-semibold rounded-md"
          >
            Write For Me
          </button>
        )}
        {buttonStatus === 'loading' && <InlineLoader className="text-3xl" />}
      </div>
      <TemplateContent isMobileView />
      {/* <DynamicTemplate />
      
      */}

      {selectedTab === TAB_RESULTS && content}
    </PageLayoutContext.Provider>
  );
}
WriterTemplateLayout.propTypes = {
  recommended: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default WriterTemplateLayout;
