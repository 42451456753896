/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { InlineLoader } from '@unbounce/ui-components';
import FormHeader from '../../../common/FormHeader';
import {
  EQUALIZER_VALUE,
  FORM_DEFAULT_VALUE,
  TAB_PROMPT,
  TAB_RESULTS,
} from '../constants';
import getSkeletons from '../../../templates/components/skeletonss';
import getForms from '../../../templates/forms';
import { storeRecent } from '../../../templates/helpers';
import { useCurrentTemplate } from '../../../templates/hooks';
// eslint-disable-next-line import/no-cycle
import { checkIfTools } from '../../../tools/actions';
import req from '../../../../redux-setup';
import { getGeneratorId } from '../actions';
import { PageLayoutContext, useStoredDynamicData } from '../hooks';
import CreditsBar from '../../../templates/components/CreditsBar';
import { setOpenUpgradeModal } from '../../../../redux-setup/actions/upgradeModal';
import Language from '../../../../common/Language';
import SelectProject from '../../../../common/Profile/SelectProject';
import FineTune from './FineTune';
import { NotificationManager } from 'react-notifications';
import Loader from '../../../common/Loader/Loader';
import ManageProjects from './ManageProjects';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
function PageLayout({ template, children, title, templateCode }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const templateData = useCurrentTemplate();
  const isTools = checkIfTools(template);
  const {
    remaining_credits,
    is_unlimited: isUnlimited,
    pack_credits,
  } = useSelector((state) => state['PERSONALIZE/DATA']);
  const loading = useSelector((state) => state.loading);
  const buttonStatus = useSelector((state) => state.BUTTON_STATUS);
  const { id: profileId } = useSelector((state) => state.PROJECT);
  const templateValues = useSelector((state) => state.TEMPLATE_VALUES) || {};
  const formRef = React.useRef();
  const selectedProfile = useSelector((state) => state['PROJECT']);

  useStoredDynamicData();

  const [equalizerValue, setEqValue] = React.useState({
    tone: 'Friendly',
    length: 'Medium',
  });
  const [viewCustomizeForm, setCustomizeForm] = React.useState(true);
  const [selectedTab, setSelectedTab] = useState(TAB_PROMPT);
  const contentValue = React.useMemo(
    () =>
      templateValues[isEmpty(templateData) ? template : templateData.code] ||
      {},
    [templateData, templateValues, template],
  );

  const showSkeleton = React.useMemo(
    () => buttonStatus === 'loading',
    [buttonStatus],
  );
  const Skeleton = getSkeletons[template] || getSkeletons.default;
  const Form = getForms[template] || getForms.default;
  const content = React.useMemo(
    () => (
      <>
        {showSkeleton && <Skeleton />}
        {typeof children === 'function'
          ? children({
              contents: Array.isArray(contentValue?.contents)
                ? contentValue.contents.map((objC) =>
                    typeof objC.content === 'string'
                      ? {
                          ...objC,
                          content: objC.content.replaceAll(/^\n+/g, ''),
                        }
                      : objC,
                  )
                : [],
            })
          : children}
      </>
    ),
    [children, showSkeleton, contentValue],
  );

  const addRecent = () => {
    storeRecent(
      title,
      `${location?.pathname}`,
      isTools ? 'recent-tools' : 'recent-templates',
    );
  };

  const handleFormSubmit = (values) => {
    const payload = { ...values };
    if (viewCustomizeForm) {
      payload.length = equalizerValue?.length;
      payload.tone = equalizerValue?.tone;
    }
    getGeneratorId(
      isEmpty(templateData) ? template : templateData.code,
      isEmpty(templateData)
        ? templateCode || title
        : {
            template_id: templateData?.templateId,
            template: templateData?.template,
          },
      profileId,
      payload,
      () => {},
      addRecent,
    );
  };

  const handleCreate = async () => {
    if (!profileId) {
      NotificationManager.error(
        'Please select the project you will generate content for.',
      );
      return;
    }
    if (
      templateData?.fine_tune === 'ideas' &&
      selectedProfile &&
      (!selectedProfile.audience?.length ||
        !selectedProfile.brand_keywords?.length ||
        !selectedProfile.description)
    ) {
      let string = '';
      string += !selectedProfile.description ? '"Description"' : '';
      string += !selectedProfile.audience.length ? ', "Audience"' : '';
      string += !selectedProfile.brand_keywords.length ? ', "Keywords"' : '';
      NotificationManager.error(
        `Please update project with ${string} or select another project`,
      );
      return;
    }
    if (!isUnlimited && (remaining_credits + pack_credits) < 1) {
      dispatch(setOpenUpgradeModal(true));
      return;
    }
    // Validate before generating results
    if (viewCustomizeForm || isTools) {
      const errors = await formRef.current.validateForm();
      if (!isEmpty(errors)) {
        // we need to submit the form because formik is not showing the errors in the refered form
        formRef.current.handleSubmit();
        setSelectedTab(TAB_PROMPT);
        return;
      }
    }
    setSelectedTab(TAB_RESULTS);
    if (viewCustomizeForm || isTools) {
      formRef.current.handleSubmit();
      return;
    }
    // if they turn off the fine tune
    getGeneratorId(
      isEmpty(templateData) ? template : templateData.code,
      isEmpty(templateData)
        ? templateCode || title
        : {
            template_id: templateData?.templateId,
            template: templateData?.template,
          },
      profileId,
      null,
      () => {},
      addRecent,
    );
  };

  useEffect(
    () => () => {
      if (isTools) {
        req.omit(EQUALIZER_VALUE);
      }
    },
    [isTools],
  );

  return (
    <PageLayoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        formRef,
        template,
        templateCode: templateCode || title,
        templateTitle: title,
        viewCustomizeForm,
        setCustomizeForm,
        equalizerValue,
        setEqValue,
        isTools,
      }}
    >
      <section className="w-full lg:space-x-8 mxl:px-16 px-8 max-w-screen-xl mx-auto">
        <div className="flex flex-wrap justify-between items-center w-full gap-2 sm:mb-4 lg:h-16">
          <FormHeader title={title} />
          <div className="flex flex-wrap gap-4 justify-center sm:justify-between w-full lg:w-auto">
            {!isUnlimited && (
              <div className="h-6 w-64 mb-4 md:mb-0 group">
                <CreditsBar />
              </div>
            )}
            {buttonStatus === 'loading' ? (
              <InlineLoader className="text-3xl" />
            ) : (
              <button
                onClick={handleCreate}
                type="button"
                className="text-center inline-flex items-center bg-ubblue hover:bg-ubblue-hover text-white text-sm py-2 px-4 font-semibold rounded-md"
              >
                Write For Me
              </button>
            )}
          </div>
        </div>
        <div className="mb-5 mt-5 border-b border-gray-200 sm:pb-0">
          <nav className="-mb-px flex space-x-8">
            <a
              href="#"
              className={clsx(
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                selectedTab === TAB_PROMPT
                  ? 'border-ubblue text-ubblue'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              )}
              onClick={() => setSelectedTab(TAB_PROMPT)}
            >
              Prompt
            </a>
            {(!isEmpty(contentValue) || buttonStatus === 'loading') && (
              <a
                href="#"
                className={clsx(
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                  selectedTab === TAB_RESULTS
                    ? 'border-ubblue text-ubblue'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                )}
                onClick={() => setSelectedTab(TAB_RESULTS)}
              >
                Results
              </a>
            )}
          </nav>
        </div>
        {isTools && (
          <div
            className={clsx('mb-6', selectedTab !== TAB_PROMPT && 'hidden')} // this needs to be hidden to not lose the ref to the form and generate new content
          >
            <Form
              ref={formRef}
              onSubmit={handleFormSubmit}
              initialValues={
                !isEmpty(contentValue.templateState)
                  ? contentValue.templateState
                  : FORM_DEFAULT_VALUE[template] || {}
              }
            />
          </div>
        )}
        <div
          className={clsx(selectedTab !== TAB_PROMPT && 'hidden')} // this needs to be hidden to not lose the ref to the form and generate new content
        >
          <div className="lg:grid lg:grid-cols-2 gap-4">
            <Language />
            <SelectProject />
          </div>
          <FineTune
            className="mt-4"
            isHidden={templateData?.fine_tune === 'no-ideas'}
          />
          <div className="mt-6 mb-4" />
          {!(templateData?.fine_tune === 'no-ideas') && selectedProfile && (
            <ManageProjects />
          )}
        </div>
        {selectedTab === TAB_RESULTS && content}
        <ScrollToTopOnMount />
      </section>
      {loading && <Loader />}
    </PageLayoutContext.Provider>
  );
}

PageLayout.defaultProps = {
  children: <div />,
  title: '',
  // storeContent: true,
  // initCustomPayload: {},
  templateCode: '',
};

PageLayout.propTypes = {
  template: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string,
  // initCustomPayload: PropTypes.objectOf(PropTypes.any),
  // storeContent: PropTypes.bool,
  templateCode: PropTypes.string,
};
export default React.memo(PageLayout);
