import React from 'react';
import { clsx } from 'clsx';
import { ISwitchOneLabelProps } from './props';

function SwitchOneLabel({
  className,
  outline,
  handleChangeSwitch,
  value,
  rightText,
  leftText,
  disableText,
}: ISwitchOneLabelProps) {
  return (
    <div className={clsx('flex items-center justify-center', className)}>
      <label
        htmlFor="default-toggle-size"
        className="inline-flex relative cursor-pointer"
      >
        <input
          type="checkbox"
          checked={value}
          id="default-toggle-size"
          className="sr-only peer"
          onChange={handleChangeSwitch}
        />
        <div
          className={clsx(
            "w-11 h-6 peer-focus:outline-none peer-focus:ring-1 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px]  after:rounded-full after:h-5 after:w-5 after:transition-all",
            outline ? 'border-2 border-silver-alto' : '',
            `peer-focus:ring-white after:bg-white`,
            value ? 'bg-shamrock' : 'bg-usilver',
          )}
        />
      </label>
      {!disableText && (
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => !value && handleChangeSwitch()}
          className={clsx(
            'ml-4 cursor-pointer text-scorpion font-source',
            value && 'font-bold',
          )}
          onClick={() => !value && handleChangeSwitch()}
        >
          {value ? rightText : leftText}
        </span>
      )}
    </div>
  );
}

export default SwitchOneLabel;
