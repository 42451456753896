/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { getGeneratorId, getYupValidation } from '../actions';
import { usePageLayoutContext } from '../hooks';
import { useApiData } from '../../../../redux-setup';

import {
  SELECTED_PERSONA,
} from '../constants';
import { getFineTuneVariable, setFineTuneVariable } from '../../../../config/localStorage';
import CustomizeFormField from "../../../../common/CustomizeForm/CustomizeFormField";

function CustomizeFormAuthor() {
  const [renderAgain, setRenderAgain] = useState(Math.random());
  const location = useLocation();
  const { id: personaId } = useApiData(SELECTED_PERSONA, {});
  const { formRef, template } = usePageLayoutContext();
  // const templateData = useCurrentTemplate(td?.slug);
  const demoId = +location.pathname.slice(1).split('/')[1];
  const templatesDemo = useApiData('templates');
  const templateData = useMemo(() => {
    return templatesDemo.filter((t) => t.id === demoId)[0];
  }, [templatesDemo, demoId]);
  const { name } = templateData;
  const { parameters, parameter_labels, parameter_placeholders, parameter_type } = templateData;

  const fields = useMemo(() => {
    return parameters.map((param, index) => ({
      field: param,
      label: parameter_labels[index],
      placeholder: parameter_placeholders[index],
      type: parameter_type[index],
    }));
  }, [parameters, parameter_labels, parameter_placeholders, parameter_type]);
  
  const initialValues = useMemo(() => {
    const objRes = {};
    fields.forEach((field) => {
      if (field.type === 'keywords') {
        objRes[field.field] = getFineTuneVariable(
          `${templateData.name.replaceAll(' ', '')}${field.field}`,
        )
          .split(',')
          .filter((elm) => elm);
      } else {
        objRes[field.field] = getFineTuneVariable(
          `${templateData.name.replaceAll(' ', '')}${field.field}`,
        );
      }
    });
    return objRes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const validationSchema = useMemo(() => {
    const customFields = fields.reduce(
      (accu, val) => ({
        ...accu,
        [val.field]: getYupValidation(val.type, val.label),
      }),
      {},
    );
    setRenderAgain(Math.random());
    return Yup.object().shape(customFields);
  }, [fields]);

  const maxLengthField = (type) => {
    switch (type) {
      case 'shortText':
        return 250;
      case 'mediumText':
        return 500;
      case 'longText':
        return 1500;
      case 'extraLongText':
        return 3000;
      case 'keywords':
        return 25;
      case 'language':
      case 'length':
      case 'tone':
      case 'input':
      default:
        return 75;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (name) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(values)) {
            setFineTuneVariable(`${name.replaceAll(' ', '')}${key}`, value);
          }
        }
        getGeneratorId(
          'V2 Template',
          isEmpty(templateData)
            ? template.name
            : {
              template_id: templateData?.templateId,
              template: templateData?.template,
            },
          personaId,
          values,
          () => null,
          () => null,
          template.id,
        );
      }}
      key={renderAgain}
    >
      {({ values }) => (
        <Form className="flex flex-wrap justify-between gap-4">
          {fields.map((field) => (
            <CustomizeFormField
              key={field.field}
              name={field.field}
              value={values[field.field]}
              title={field.label}
              placeholder={field.placeholder || field.label}
              type={field.type}
              maxCounter={maxLengthField(field.type)}
            />
          ))}
          <button type="submit" className="hidden" />
        </Form>
      )}
    </Formik>
  );
}
CustomizeFormAuthor.defaultProps = {
  isDemo: false,
};
CustomizeFormAuthor.propTypes = {
  isDemo: PropTypes.bool,
};
export default CustomizeFormAuthor;
