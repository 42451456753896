import React from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { usePageLayoutContext } from '../../hooks';
import { IFineTuneProps, IPageLayoutContext } from './props';
import SwitchOneLabel from '../../../../../common/SwitchOneLabel';
import CustomizeForm from '../../../../../common/CustomizeForm';

function FineTune({
  isHidden,
  className,
  isDemo,
  isSidebar,
  onSubmitForm,
}: IFineTuneProps) {
  const { viewCustomizeForm, setCustomizeForm, isWriter } =
    usePageLayoutContext() as IPageLayoutContext;

  return (
    <div className={clsx(className)}>
      {!isHidden && (
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faSparkles} />
              <h3 className="ml-2 font-source font-semibold inline-block text-ugray text-sm">
                Brainstorm Mode
              </h3>
            </div>
            {!isSidebar && (
              <span className="text-ugray text-xs font-source hidden lg:inline-block">
                {viewCustomizeForm
                  ? 'Enable to write random ideas/inspiration based on selected project'
                  : 'Disable to write copy specific to your needs'}
              </span>
            )}
          </div>
          {!isWriter && (
            <SwitchOneLabel
              rightText="Enabled"
              value={!viewCustomizeForm}
              leftText="Disabled"
              handleChangeSwitch={() => {
                setCustomizeForm((prev) => !prev);
              }}
              disableText={isSidebar}
            />
          )}
        </div>
      )}
      <div className="mt-4" />
      {viewCustomizeForm && (
        <CustomizeForm isSidebar={isSidebar} onSubmitForm={onSubmitForm} />
      )}
    </div>
  );
}

export default FineTune;
