/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { API, displayError, handleErrors } from '../../../config/API';
import { setToken } from '../../../config/token';
import { getRewardfulCode } from '../../helpers';
import RegisterForm from '../forms/RegisterForm';
import req from '../../../redux-setup';
import { AUTHENTICATION } from '../../auth/constants';
import { setLoading } from '../../../redux-setup/actions/loading';
import { getCouponInfo } from '../../../common/getInfo';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

{
  /* Right now the plans will be
  For old accounts
  - Starter
    - starter
  - Growth
    - growth
    - ubgrowth
  For some new accounts
  - Free
    - free
    - cpfree
  - Essential
    - essential
    - cpessential
  - Unlimited
    - unlimited
    - cpunlimited
*/
}

// possible values here starter, growth, ubgrowth, free, essential, cpessential, unlimited, cpunlimited,
// we can add more plans, and we need to update the backend also
const firstPlanF = (pricingPlan) => {
  switch (pricingPlan) {
    case 'starter':
    case 'free':
    case 'cpfree':
      return 'free';
    case 'growthMonth':
    case 'growthYear':
      return 'growth';
    case 'ubgrowthMonth':
    case 'ubgrowthYear':
      return 'ubgrowth';
    case 'cpessentialMonth':
    case 'cpessentialYear':
      return 'cpessential';
    case 'essentialMonth':
    case 'essentialYear':
      return 'essential';
    case 'unlimitedYear':
    case 'unlimitedMonth':
      return 'unlimited';
    case 'cpunlimitedYear':
    case 'cpunlimitedMonth':
      return 'cpunlimited';
    default:
      return '';
  }
};
// possible values here 'monthtly, annual, free, if we add more we need to update the backend
const firstTenureF = (pricingPlan) => {
  switch (pricingPlan) {
    case 'free':
    case 'cpfree':
    case 'starter':
      return 'free';
    case 'ubgrowthMonth':
    case 'growthMonth':
    case 'essentialMonth':
    case 'cpessentialMonth':
    case 'unlimitedMonth':
    case 'cpunlimitedMonth':
      return 'monthly';
    case 'ubgrowthYear':
    case 'growthYear':
    case 'essentialYear':
    case 'cpessentialYear':
    case 'unlimitedYear':
    case 'cpunlimitedYear':
      return 'annual';
    default:
      return 'free';
  }
};

function SignUp({ plan, tenure }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [options, setOptions] = useState([
    {
      value: 'free',
      label: 'Free - Free forever',
    },
    {
      value: 'essentialMonth',
      label: 'Essential - $9 Billed Monthly',
    },
    {
      value: 'essentialYear',
      label: 'Essential - $96 Billed Annually',
    },
    {
      value: 'unlimitedMonth',
      label: 'Unlimited - $49 Billed Monthly',
    },
    {
      value: 'unlimitedYear',
      label: 'Unlimited - $480 Billed Annually',
    },
  ]);
  const [startValue, setStartValue] = useState({ value: '', label: '' });
  const [keyR, setKeyR] = useState(Math.random() * 1000);
  const [registerFormValue, setRegisterFormValue] = useState({
    name: '',
    company_name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    code: '',
    pricingPlan: '',
  });
  let query = useQuery();

  const handleSignup = async (values, setSubmitting) => {
    dispatch(setLoading(true));
    const rewardful = getRewardfulCode();
    const medium = query.get('utm_medium') || '';
    const campaign = query.get('utm_campaign') || '';
    const source = query.get('utm_source') || '';
    try {
      const res = await Axios.post(`${API}/register`, {
        ...values,
        rewardful,
        first_plan: firstPlanF(values.pricingPlan),
        first_tenure: firstTenureF(values.pricingPlan),
        utm_medium: medium,
        utm_campaign: campaign,
        utm_source: source,
      });
      setToken(res.data.access_token);
      req.set(AUTHENTICATION, true);
      history.push('/validate');
    } catch (error) {
      handleErrors(error);
      displayError('register', error);
      window.scrollTo(0, 0);
    } finally {
      dispatch(setLoading(false));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    switch (plan) {
      case 'ubgrowth':
        setOptions([
          {
            value: 'unlimitedMonth',
            label: 'Unlimited - $49 Billed Monthly',
          },
          {
            value: 'unlimitedYear',
            label: 'Unlimited - $480 Billed Annually',
          },
          {
            value: 'ubgrowthMonth',
            label: 'Unlimited - $49 Billed Monthly (90 Days Free)',
          },
          {
            value: 'ubgrowthYear',
            label: 'Unlimited - $480 Billed Annually (90 Days Free)',
          },
        ]);
        setRegisterFormValue((preFV) => ({
          ...preFV,
          pricingPlan: tenure === 'annual' ? 'ubgrowthYear' : 'ubgrowthMonth',
        }));
        setStartValue(
          tenure === 'annual'
            ? {
                value: 'ubgrowthYear',
                label: 'Unlimited - $480 Annual (90 Days Free)',
              }
            : {
                value: 'ubgrowthMonth',
                label: 'Unlimited - $49 Monthly (90 Days Free)',
              },
        );
        // force the render of the form with the select
        setKeyR(Math.random() * 1000);
        break;
      case 'starter':
      case 'free': {
        // we look for the query variable code
        let pricingPlan = 'free';
        setRegisterFormValue((preFV) => ({
          ...preFV,
          pricingPlan: pricingPlan,
        }));
        setStartValue({
          value: pricingPlan,
          label: 'Free - Free forever',
        });
        // force the render of the form with the select
        setKeyR(Math.random() * 1000);
        break;
      }
      case 'essential': {
        const tenureAnnual = tenure === 'annual';
        let pricingPlan = '';
        let startValue = { value: '', label: '' };
        setOptions([
          {
            value: 'essentialMonth',
            label: 'Essential - $9 Billed Monthly',
          },
          {
            value: 'essentialYear',
            label: 'Essential - $96 Billed Annually',
          },
          {
            value: 'unlimitedMonth',
            label: 'Unlimited - $49 Billed Monthly',
          },
          {
            value: 'unlimitedYear',
            label: 'Unlimited - $480 Billed Annually',
          },
        ]);
        pricingPlan = tenureAnnual ? 'essentialYear' : 'essentialMonth';
        startValue = tenureAnnual
          ? {
              value: 'essentialYear',
              label: 'Essential - $96 Billed Annually',
            }
          : {
              value: 'essentialMonth',
              label: 'Essential - $9 Billed Monthly',
            };
        setRegisterFormValue((preFV) => ({
          ...preFV,
          pricingPlan,
        }));
        setStartValue(startValue);
        // force the render of the form with the select
        setKeyR(Math.random() * 1000);
        break;
      }
      case 'growth':
      case 'unlimited': {
        const tenureAnnual = tenure === 'annual';
        let pricingPlan = '';
        let startValue = { value: '', label: '' };
        setOptions([
          {
            value: 'unlimitedMonth',
            label: 'Unlimited - $49 Billed Monthly',
          },
          {
            value: 'unlimitedYear',
            label: 'Unlimited - $480 Billed Annually',
          },
        ]);
        pricingPlan = tenureAnnual ? 'unlimitedYear' : 'unlimitedMonth';
        startValue = tenureAnnual
          ? {
              value: 'unlimitedYear',
              label: 'Unlimited - $480 Billed Annually',
            }
          : {
              value: 'unlimitedMonth',
              label: 'Unlimited - $49 Billed Monthly',
            };

        setRegisterFormValue((preFV) => ({
          ...preFV,
          pricingPlan,
        }));
        setStartValue(startValue);
        // force the render of the form with the select
        setKeyR(Math.random() * 1000);
        break;
      }
      default:
        break;
    }
  }, [plan, tenure]);

  useEffect(() => {
    const coupon = query.get('coupon') || '';
    async function obtainData() {
      const response = await getCouponInfo(coupon);
      const result = await response.json();
      if (result.data) {
        setOptions([result.data.option]);
        setStartValue(result.data.option);
        setRegisterFormValue((preFV) => ({
          ...preFV,
          code: coupon,
          pricingPlan: result.data.option.value,
        }));
        setKeyR(Math.random() * 1000);
      }
    }
    if (coupon) {
      obtainData();
    }
  }, [query]);
  return (
    <div className="mt-10">
      <RegisterForm
        key={keyR}
        handleSignup={handleSignup}
        defaultValue={registerFormValue}
        options={options}
        startValue={startValue}
      />
      <div style={{ marginTop: 25 }}>
        <Link
          to="/"
          className="text-center block text-md text-ubblue hover:text-ubblue-hover mt-2 font-source"
        >
          Have an account already? Sign in now!
        </Link>
      </div>
      <div className="text-center block text-md text-gray-400 mt-2 pb-4 font-source">
        {'By signing up you agree to the following '}
        <a
          href="https://unbounce.com/terms-of-service/"
          className="underline font-source"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
        {' and '}
        <a
          href="https://unbounce.com/privacy/"
          className="underline font-source"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default SignUp;
