import React, { useState, useEffect, useMemo, useRef } from 'react';

import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { InlineLoader } from '@unbounce/ui-components';

import { getGeneratorId } from '../partials/pagelayout/actions';
import {
  BUTTON_STATUS,
  EQUALIZER_VALUE,
  SELECTED_PERSONA,
  TAB_PROMPT,
  TAB_RESULTS,
} from '../partials/pagelayout/constants';
import { PageLayoutContext } from '../partials/pagelayout/hooks';
import { checkIfTools } from '../tools/actions';
import req, { useApiData } from '../../redux-setup';

import NotAuthoringTemplate from './NotAuthoringTemplate';
import { getTemplates } from './actions';
import CustomizeAuthor from '../partials/pagelayout/containers/CustomizeAuthor';

function AuthorResults({ template, templateCode, title }) {
  const CustomCards = useApiData('customtemplateresults');
  const personaDetails = useApiData(SELECTED_PERSONA, {});
  const buttonStatus = useApiData(BUTTON_STATUS, '');

  const { id } = personaDetails;
  const isTools = useMemo(() => checkIfTools(template), [template]);

  const [AuthorCards, setAuthorCards] = useState([]);
  const [viewCustomizeForm, setCustomizeForm] = useState(true);
  const [selectedTab, setSelectedTab] = useState(TAB_PROMPT);
  const [equalizerValue, setEqValue] = useState({
    tone: 'Friendly',
    length: 'Medium',
  });
  const formRef = useRef();

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    setAuthorCards(CustomCards);
  }, [CustomCards]);

  /*
  Generates the content with the user specifications
  Pregenerated templates (viewCustomizeForm and isTools)
  Base template (V2 Template)
  */
  const handleCreate = async () => {
    if (viewCustomizeForm || isTools) {
      // Validate before generating results
      const errors = await formRef.current.validateForm();
      if (!isEmpty(errors)) {
        // we need to submit the form because formik is not showing the errors in the refered form
        formRef.current.handleSubmit();
        setSelectedTab(TAB_PROMPT);
        return;
      }

      // if there are no errors we go to the results tab and submit the form
      setSelectedTab(TAB_RESULTS);
      formRef.current.handleSubmit();
      return;
    }

    setSelectedTab(TAB_RESULTS);
    await getGeneratorId(
      'V2 Template',
      template.name,
      id,
      {},
      () => null,
      () => null,
      template.id,
    );
  };

  React.useEffect(
    () => () => {
      if (isTools) {
        req.omit(EQUALIZER_VALUE);
      }
    },
    [isTools],
  );

  return (
    <PageLayoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        formRef,
        template,
        templateCode: templateCode || title,
        templateTitle: title,
        viewCustomizeForm,
        setCustomizeForm,
        equalizerValue,
        setEqValue,
        isTools,
      }}
    >
      <div className="w-full max-w-screen-xl pb-10 transition-all duration-500">
        <div className="flex justify-end items-center relative w-full sm:mb-4 lg:h-16">
          {buttonStatus !== 'loading' && (
            <button
              onClick={handleCreate}
              type="button"
              className="inline-flex items-center bg-ubblue hover:bg-ubblue-hover text-white text-sm py-2 px-4 font-semibold rounded-md"
            >
              Write For Me
            </button>
          )}
          {buttonStatus === 'loading' && <InlineLoader className="text-3xl" />}
        </div>
        <div className="mb-5 mt-5 border-b border-gray-200 sm:pb-0">
          <nav className="-mb-px flex space-x-8">
            <button
              type="button"
              href="#"
              className={clsx(
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                selectedTab === TAB_PROMPT
                  ? 'border-ubblue text-ubblue'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              )}
              onClick={() => setSelectedTab(TAB_PROMPT)}
            >
              Prompt
            </button>
            {(!isEmpty(AuthorCards) || buttonStatus === 'loading') && (
              <button
                type="button"
                href="#"
                className={clsx(
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                  selectedTab === TAB_RESULTS
                    ? 'border-ubblue text-ubblue'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                )}
                onClick={() => setSelectedTab(TAB_RESULTS)}
              >
                Results
              </button>
            )}
          </nav>
        </div>
        <div
          className={clsx(selectedTab !== TAB_PROMPT && 'hidden')} // this needs to be hidden to not lose the ref to the form and generate new content
        >
          <CustomizeAuthor isPaid isDemo />
        </div>
        {selectedTab === TAB_RESULTS && (
          <NotAuthoringTemplate cards={AuthorCards} />
        )}
      </div>
    </PageLayoutContext.Provider>
  );
}

AuthorResults.defaultProps = {
  template: {},
  templateCode: null,
  title: '',
};

AuthorResults.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.object,
  templateCode: PropTypes.string,
  title: PropTypes.string,
};

export default AuthorResults;
